import '../css/index.css';

import Head from 'next/head';
import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
});

function App({ Component, pageProps }) {
  React.useEffect(() => console.info('Follow us on Twitter, https://twitter.com/authmoji'), []);

  const getLayout = Component.getLayout || (page => page);

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Head>
          <title>Authmoji | Two Factor Authentication with Flair</title>
          <meta
            name="Description"
            content="Authmoji delivers a robust API and app that helps you verify and secure users and future proof your business using emoji based two-factor authentication."
          />
          <meta property="og:title" content="Authmoji | Two Factor Authentication with Flair" />
          <meta
            property="og:description"
            content="Authmoji delivers a robust API and app that helps you verify and secure users and future proof your business using emoji based two-factor authentication."
          />
          <meta
            property="og:image"
            content="https://static.tildacdn.com/tild6362-6266-4164-a433-643939616438/Screen_Shot_2021-05-.png"
          />
          <meta property="og:url" content="https://authmoji.com" />
          <meta name="twitter:site" content="@authmoji" />

          <link
            rel="icon"
            href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🔑</text></svg>"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          <script async data-api="/_shipworthy" src="/shipworthy.js"></script>
        </Head>

        {getLayout(<Component {...pageProps}></Component>, pageProps)}
      </div>
    </QueryClientProvider>
  );
}

export default App;
